<template>
  <Component
    :is="buttonOrNuxtLink"
    :to="to"
    hreflang="de"
    class="text-theme focus-default group/btn bg-gradient-custom relative flex w-max items-center overflow-hidden rounded-full border-2 border-current py-3.5 px-5 text-xs font-medium ring-offset-2 transition-colors duration-300 lg:py-4"
  >
    <Component
      :is="to ? 'IconArrowSimple' : 'IconPlus'"
      class="relative mr-4 h-6 w-6 group-hover/btn:!text-white group-focus-visible/btn:!text-white"
    />
    <span
      class="relative group-hover/btn:!text-white group-focus-visible/btn:!text-white"
    >
      <slot />
    </span>
  </Component>
</template>

<script setup>
const props = defineProps({
  to: { type: [String, Object], default: null },
})

// determine whether to load NuxtLink or button
const buttonOrNuxtLink = computed(() => {
  if (props.to) {
    return resolveComponent('NuxtLink')
  }

  return 'button'
})
</script>

<style scoped>
.bg-gradient-custom {
  background: linear-gradient(
    90deg,
    currentColor 0%,
    currentColor 50%,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0) 100%
  );
  background-position: 100%;
  background-size: 200% 100%;
  @apply transition-all;
}
.bg-gradient-custom:hover,
.bg-gradient-custom:focus-visible {
  @apply transition-all;
  background-position: 0%;
}
</style>
